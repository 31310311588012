import React from "react";

export default function PillItem({ text, bgColor = "bg-primary" }) {
  return (
    <div
      className={`${bgColor} text-white rounded-pill py-1 px-2 font-w500 fs-6`}
      style={{ width: "max-content" }}
    >
      <p className="mb-0">{text}</p>
    </div>
  );
}
