import axios from "axios";
import { PER_PAGE_SIZE } from "../../utils/constants";

export const postSavePayment = async (data) => {
  try {
    let link = process.env.REACT_APP_LAMBDA_URL + `/v1/save-payment`;
    const response = await axios.post(link, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getHistoryPayment = async ({
  page = 1,
  size = PER_PAGE_SIZE,
  from,
  to,
  method,
  category_item_id,
  doctor_id,
  busines_id,
  paymentType = null,
  current_utc = +7,
}) => {
  try {
    let link = `${process.env.REACT_APP_LAMBDA_URL}/v1/history-payment-item?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);
    link = link.concat("&from=" + from);
    link = link.concat("&to=" + to);
    if (method) link = link.concat("&method=" + method);
    if (category_item_id)
      link = link.concat("&category_item_id=" + category_item_id);
    if (doctor_id) link = link.concat("&doctor_id=" + doctor_id);
    if (busines_id) link = link.concat("&busines_id=" + busines_id);
    if (paymentType) link = link.concat("&paymentType=" + paymentType);
    if (current_utc) link = link.concat("&current_utc=" + current_utc);

    const response = await axios.get(link);

    return response;
  } catch (error) {
    return error;
  }
};

export const exportHistoryPayment = async ({
  from,
  to,
  method,
  category_item_id,
  doctor_id,
  busines_id,
  current_utc = +7,
}) => {
  try {
    let link = `${process.env.REACT_APP_LAMBDA_URL}/v1/export-payment-item?`;
    link = link.concat("from=" + from);
    link = link.concat("&to=" + to);
    if (method) link = link.concat("&method=" + method);
    if (category_item_id)
      link = link.concat("&category_item_id=" + category_item_id);
    if (doctor_id) link = link.concat("&doctor_id=" + doctor_id);
    if (busines_id) link = link.concat("&busines_id=" + busines_id);
    if (current_utc) link = link.concat("&current_utc=" + current_utc);

    const response = await axios.get(link);

    return response;
  } catch (error) {
    return error;
  }
};
