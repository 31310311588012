import React from "react";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { Link } from "react-router-dom";
import Table from "../../../../components/Table";
// import FilterPatient from "../../../../components/FilterPatient";
import Button from "../../../../components/Button";
import {
  formatOptionsToArray,
  formatWithCurrentUTC,
} from "../../../../../utils/utils";
import PillItem from "../../../../components/PillItem";
import { useHistory } from "react-router-dom";

export default function ListActiveVisitComponent({
  dataPatient,
  isLoading = false,
  isLoadingEnd = false,
  totalPage = 0,
  totalEntries = 0,
  handlePagination,
  mutateEndVisit,
  handleSetFilter,
  activePage,
  sizePerPage,
  setSizePerPage,
}) {
  const localize = useLocalization();
  const history = useHistory();
  const colorsAllergys = ["bg-blue", "bg-orange", "bg-danger"];

  const columns = [
    { label: localize.getText("sequenceNumber") },
    { label: localize.getText("noRm") },
    { label: localize.getText("doctor") },
    { label: localize.getText("name") },
    { label: localize.getText("timeVisit") },
    { label: localize.getText("alergies"), style: { width: "400px" } },
    { label: localize.getText("dateOfBirth") },
    { label: localize.getText("gender") },
    {
      label: localize.getText("action"),
      className: "text-center",
      style: { width: "200px" },
    },
  ];

  return (
    <div className="card">
      <div className="card-body">
        <Table
          columns={columns}
          loading={isLoading}
          activePage={activePage}
          totalPage={totalPage}
          totalShow={dataPatient.length}
          totalEntries={totalEntries}
          handlePagination={handlePagination}
          sizePerPage={sizePerPage}
          handleSizePerPage={setSizePerPage}
          isHovered
        >
          {dataPatient.map((item, index) => (
            <tr
              key={index}
              onClick={() =>
                history.push({
                  pathname: "/active-visit/assesment",
                  search: `?id=${item.activeVisitId}&patientId=${item.id}`,
                  state: item,
                })
              }
              className="c-pointer"
            >
              <td>{item?.visit?.sequence}</td>
              <td className="sorting_1">{item?.medicalRecordNumber || "-"}</td>
              <td>{item?.visit?.doctorName}</td>
              <td>{item.name}</td>
              <td>{formatWithCurrentUTC(item.visit.startInString)}</td>
              <td>
                <div className="d-flex flex-wrap gap-2">
                  {formatOptionsToArray(item.allergy).length > 0
                    ? formatOptionsToArray(item.allergy).map((item, index) => (
                        <PillItem
                          text={item}
                          key={index}
                          bgColor={
                            colorsAllergys[index % colorsAllergys.length]
                          }
                        />
                      ))
                    : "-"}
                </div>
              </td>
              <td>{item.dob}</td>
              <td>{item.gender}</td>

              <td>
                <div className="d-flex justify-content-center gap-3">
                  <Link
                    to={{
                      pathname: "/active-visit/assesment",
                      search: `?id=${item.activeVisitId}&patientId=${item.id}`,
                      state: item,
                    }}
                  >
                    <Button variant="success" tooltip="Assesment">
                      <i class="fas fa-file-alt"></i>
                    </Button>
                  </Link>
                  {item.activeVisitId && (
                    <Button
                      variant="danger"
                      tooltip="End Visit"
                      onClick={(e) => {
                        e.stopPropagation();
                        mutateEndVisit(item);
                      }}
                      loading={isLoadingEnd}
                    >
                      <i class="fas fa-calendar-times"></i>
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
