import React from "react";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { Link } from "react-router-dom";
import FilterPatient from "../../../../components/FilterPatient";
import Table from "../../../../components/Table";
import Button from "../../../../components/Button";
import { formatOptionsToArray } from "../../../../../utils/utils";
import PillItem from "../../../../components/PillItem";

export default function ListPatientComponent({
  dataPatient,
  isLoading = false,
  isLoadingStart = false,
  totalPage = 0,
  totalEntries = 0,
  sizePerPage = 0,
  setSizePerPage,
  handlePagination,
  mutateStartVisit,
  activePage,
  handleSetFilter,
}) {
  const localize = useLocalization();
  const colorsAllergys = ["bg-blue", "bg-orange", "bg-danger"];

  const columns = [
    { label: localize.getText("noRm"), style: { fontSize: 14 } },
    { label: localize.getText("name"), style: { fontSize: 14 } },
    {
      label: localize.getText("alergies"),
      style: { width: "200px", fontSize: 14 },
    },
    { label: localize.getText("phoneNumber"), style: { fontSize: 14 } },
    { label: localize.getText("dateOfBirth"), style: { fontSize: 14 } },
    { label: localize.getText("gender"), style: { fontSize: 14 } },
    {
      label: localize.getText("address"),
      style: { fontSize: 14 },
    },
    {
      label: localize.getText("action"),
      className: "text-center",
      style: { width: "200px", fontSize: 14 },
    },
  ];
  return (
    <div className="card">
      <div className="card-body">
        <FilterPatient handleSetFilter={handleSetFilter} isAddButton />
        <Table
          columns={columns}
          loading={isLoading}
          activePage={activePage}
          totalPage={totalPage}
          totalShow={dataPatient?.length}
          totalEntries={totalEntries}
          handlePagination={handlePagination}
          sizePerPage={sizePerPage}
          handleSizePerPage={setSizePerPage}
        >
          {dataPatient?.map((item, index) => (
            <tr key={index}>
              <td className="sorting_1">{item?.medicalRecordNumber || "-"}</td>
              <td>{item.name}</td>
              <td>
                <div className="d-flex flex-wrap gap-2">
                  {formatOptionsToArray(item.allergy).length > 0
                    ? formatOptionsToArray(item.allergy).map((item, index) => (
                        <PillItem
                          text={item}
                          key={index}
                          bgColor={
                            colorsAllergys[index % colorsAllergys.length]
                          }
                        />
                      ))
                    : "-"}
                </div>
              </td>
              <td>{item.phoneNumber}</td>
              <td>{item.dob}</td>
              <td>{item.gender}</td>
              <td>{item.address}</td>
              <td>
                <div className="d-flex justify-content-center gap-3">
                  {!item.activeVisitId && (
                    <Button
                      variant="success"
                      tooltip="Start Visit"
                      onClick={() => mutateStartVisit(item)}
                      loading={isLoadingStart}
                    >
                      <i class="fas fa-calendar-plus"></i>
                    </Button>
                  )}
                  <Link
                    to={{
                      pathname: "/history",
                      search: `?id=${item.id}`,
                    }}
                  >
                    <Button tooltip="History">
                      <i class="fas fa-history"></i>
                    </Button>
                  </Link>
                  <Link
                    to={{
                      pathname: "/patient/edit-patient",
                      search: `?id=${item.id}`,
                      state: { ...item, isEdit: true },
                    }}
                  >
                    <Button tooltip="Edit" variant="info">
                      <i class="fas fa-edit"></i>
                    </Button>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
