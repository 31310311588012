import React, { useState, useEffect } from "react";
import styles from "./FormPayment.module.css";
import { Row, Col } from "react-bootstrap";
import cx from "classnames";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useQuery } from "@tanstack/react-query";

import InputField from "../InputField";
import SelectField from "../SelectField";
import AsyncSelect from "../AsyncSelect";
import Button from "../Button";
import {
  paymentMethod,
  typeDiscount,
  paymentItemType,
} from "../../../utils/constants";
import { formatNumberWithCurrency, findItemById } from "../../../utils/utils";
import { getItemOptions } from "../../../services/Handlers/MasterItemsService";
import WrapperContent from "../WrapperContent";
import { useLocalization } from "../../../context/LocalizeContextProvider";

const emptyPaymentItem = {
  item: null,
  qty: 1,
  amount: null,
  typeDiscount: null,
  discount: null,
};

export default function FormPayment({
  onSubmit = () => undefined,
  onClickCancel = () => undefined,
  businessId,
  value = null,
  disabledForm = false,
  isCancel = false,
  isFieldUser = false,
  loading = false,
  title = "Pembayaran",
}) {
  const localize = useLocalization();

  const [itemOptions, setItemOptions] = useState([]);
  const [paymentData, setPaymentData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    method: "",
    cardNumber: "",
    paymentItems: [
      {
        item: null,
        qty: 1,
        amount: null,
        typeDiscount: null,
        discount: null,
      },
    ],
  });

  useQuery(["GetItemsOption", businessId], () => getItemOptions(businessId), {
    onSuccess: (res) => {
      if (res.data && res.status === 200) {
        const response = res.data;
        setItemOptions(response);
      }
    },
    enabled: !!businessId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (value && !paymentData.method && itemOptions.length > 0) {
      const paymentItems = [];
      if (
        value.payment_items.filter(
          (value) => value.type === paymentItemType.CONSULTATION
        ).length > 0
      ) {
        value.payment_items
          .filter((value) => value.type === paymentItemType.CONSULTATION)
          .forEach((item) => {
            paymentItems.push({
              item: findItemById(itemOptions, item.item_id),
              qty: item.quantity,
              amount: item.amount,
              typeDiscount: item.type_discount,
              discount: item.discount,
            });
          });
      } else {
        paymentItems.push(emptyPaymentItem);
      }
      setPaymentData({
        method: value.payment_method,
        cardNumber: value.card_number,
        paymentItems,
      });
    }
  }, [value, itemOptions, paymentData]);

  const handleAddPaymentItem = () => {
    const dataPaymentItems = [...paymentData.paymentItems];
    dataPaymentItems.push(emptyPaymentItem);

    setPaymentData((prevState) => ({
      ...prevState,
      paymentItems: dataPaymentItems,
    }));
  };

  const handleDeletePaymentItem = (index) => {
    const dataPaymentItems = [...paymentData.paymentItems];
    dataPaymentItems.splice(index, 1);

    setPaymentData((prevState) => ({
      ...prevState,
      paymentItems: dataPaymentItems,
    }));
  };

  const handleChangeForm = (name, value, nameItem, indexItem) => {
    const dataPayment = JSON.parse(JSON.stringify({ ...paymentData }));
    if (!nameItem && !indexItem) {
      dataPayment[name] = value;
    } else {
      dataPayment[name].forEach((item, index) => {
        if (index === indexItem) {
          if (nameItem === "item") {
            const selectedItem = findItemById(itemOptions, value.value);
            item["amount"] = selectedItem.price;
          }
          item[nameItem] = value;
        }
      });
    }
    setPaymentData(dataPayment);
  };

  const requiredArray = (value) =>
    value && value.length > 0 ? undefined : "Required";

  const requiredField = (value, label) => {
    if (!value) {
      return `Harap masukkan ${label}`;
    }

    return undefined;
  };

  const handleSubmitPayment = () => {
    const dataPaymentCorrection =
      value?.payment_items
        .filter((value) => value.type === paymentItemType.CORRECTION)
        .map((item) => ({
          itemId: item.item_id,
          itemName: item.item_name_original || item.item_name,
          qty: item.quantity,
          amount: item.amount,
          typeDiscount: null,
          discount: null,
          type: item.type,
        })) || [];
    const dataPayment = paymentData.paymentItems.map((item) => ({
      ...item,
      itemId: item.item.value,
      itemName: item.item.label,
      type: isFieldUser
        ? paymentItemType.FARMASI
        : paymentItemType.CONSULTATION,
    }));

    const data = {
      ...paymentData,
      totalAmountCorrection: value?.total_amount_correction || 0,
      totalAmount,
      paymentItems: [...dataPayment, ...dataPaymentCorrection],
    };

    onSubmit(data);
  };

  const totalAmount = paymentData.paymentItems
    .filter((item) => item.amount !== null)
    .reduce((sum, current) => {
      const amount = Number(current.amount) || 0;
      const discountType = current.typeDiscount;
      const discount = Number(current.discount) || 0;

      let discountedAmount = amount;

      if (discountType === "PERCENTAGE" && discount > 0) {
        discountedAmount -= (amount * discount) / 100;
      } else if (discountType === "NUMBER" && discount > 0) {
        discountedAmount -= discount;
      }

      // Ensure the discounted amount is not negative
      discountedAmount = Math.max(discountedAmount, 0);

      return sum + discountedAmount;
    }, 0);

  const isCardNumber =
    paymentData.method === "CREDIT_CARD" || paymentData.method === "DEBIT";

  return (
    <Form
      onSubmit={handleSubmitPayment}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={{ ...paymentData }}
      validate={(values) => {
        const errors = {};

        if (!values.method) {
          errors.method = "Harap masukkan Metode Pembayaran";
        }
        if (!values.cardNumber && isCardNumber) {
          errors.cardNumber = "Harap masukkan Nomor Kartu";
        }
        if (values?.cardNumber.length < 16 && isCardNumber) {
          errors.cardNumber = "Harap masukkan 16 digit Nomor Kartu";
        }
        return errors;
      }}
    >
      {({ handleSubmit }) => (
        <WrapperContent
          title={title}
          color={"primary"}
          disableBackground={disabledForm}
        >
          <div className={cx("d-flex flex-column gap-4")}>
            {isFieldUser && (
              <Row>
                <Col md={4}>
                  <Field name="name">
                    {() => (
                      <InputField
                        label={`${localize.getText("name")} (Optional)`}
                        placeholder={localize.getText("placeholderField", {
                          fieldName: localize.getText("name"),
                        })}
                        value={paymentData.name}
                        onChange={(value) => handleChangeForm("name", value)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="email">
                    {() => (
                      <InputField
                        label={`${localize.getText("email")} (Optional)`}
                        placeholder={localize.getText("placeholderField", {
                          fieldName: localize.getText("email"),
                        })}
                        value={paymentData.email}
                        onChange={(value) => handleChangeForm("email", value)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="phoneNumber">
                    {() => (
                      <InputField
                        label={`${localize.getText("phoneNumber")} (Optional)`}
                        placeholder={localize.getText("placeholderField", {
                          fieldName: localize.getText("phoneNumber"),
                        })}
                        value={paymentData.phoneNumber}
                        onChange={(value) =>
                          handleChangeForm("phoneNumber", value)
                        }
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={isCardNumber ? 6 : 12}>
                <Field name="method">
                  {({ input, meta }) => (
                    <SelectField
                      label="Metode"
                      placeholder="Pilih Metode Pembayaran"
                      data={paymentMethod}
                      labelKey="label"
                      valueKey="value"
                      onBlur={input.onBlur}
                      error={meta.touched && meta.error}
                      value={paymentData.method}
                      onChange={(value) => handleChangeForm("method", value)}
                      disabled={disabledForm}
                    />
                  )}
                </Field>
              </Col>
              {isCardNumber && (
                <Col md={6}>
                  <Field name="cardNumber">
                    {({ input, meta }) => (
                      <InputField
                        label="Nomor Kartu"
                        placeholder="Masukkan Nomor Kartu"
                        onBlur={input.onBlur}
                        error={meta.touched && meta.error}
                        value={paymentData.cardNumber}
                        onChange={(value) =>
                          handleChangeForm("cardNumber", value)
                        }
                        disabled={disabledForm}
                      />
                    )}
                  </Field>
                </Col>
              )}
            </Row>
            <div className="d-flex flex-column gap-1">
              <Row>
                <Col md={3}>
                  <p className={styles.labelForm}>Nama</p>
                </Col>
                <Col md={3}>
                  <p className={styles.labelForm}>Jumlah</p>
                </Col>
                <Col md={2}>
                  <p className={styles.labelForm}>Harga</p>
                </Col>
                <Col
                  md={4}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: 51,
                  }}
                >
                  <div>
                    <p className={styles.labelForm}>Tipe Diskon</p>
                  </div>
                  <div>
                    <p className={styles.labelForm}>Diskon</p>
                  </div>
                </Col>
              </Row>
              <FieldArray name="paymentItems" validate={requiredArray}>
                {({ fields }) => (
                  <div className="d-flex flex-column gap-3">
                    {fields?.value.map((field, index) => (
                      <Row key={index}>
                        <Col md={3}>
                          <Field
                            name={`paymentItems[${index}].item`}
                            validate={(value) => requiredField(value, "Item")}
                          >
                            {({ input, meta }) => (
                              <AsyncSelect
                                placeholder="Pilih Item"
                                isGroup
                                onBlur={input.onBlur}
                                error={meta.touched && meta.error}
                                value={paymentData.paymentItems[index]?.item}
                                options={itemOptions}
                                labelKey="label"
                                valueKey="value"
                                disabled={disabledForm}
                                onChange={(value) =>
                                  handleChangeForm(
                                    "paymentItems",
                                    value,
                                    "item",
                                    index
                                  )
                                }
                              />
                            )}
                          </Field>
                        </Col>
                        <Col md={3}>
                          <Field
                            name={`paymentItems[${index}].qty`}
                            validate={(value) => requiredField(value, "Jumlah")}
                          >
                            {({ input, meta }) => (
                              <InputField
                                placeholder={`Masukkan Jumlah`}
                                onBlur={input.onBlur}
                                error={meta.touched && meta.error}
                                value={paymentData.paymentItems[index]?.qty}
                                disabled={disabledForm}
                                onChange={(value) =>
                                  handleChangeForm(
                                    "paymentItems",
                                    value,
                                    "qty",
                                    index
                                  )
                                }
                              />
                            )}
                          </Field>
                        </Col>
                        <Col md={2}>
                          <Field
                            name={`paymentItems[${index}].amount`}
                            validate={(value) => requiredField(value, "Harga")}
                          >
                            {({ input, meta }) => (
                              <InputField
                                placeholder="Masukkan Harga"
                                type="number"
                                onBlur={input.onBlur}
                                error={meta.touched && meta.error}
                                value={paymentData.paymentItems[index]?.amount}
                                disabled={disabledForm}
                                onChange={(value) =>
                                  handleChangeForm(
                                    "paymentItems",
                                    value,
                                    "amount",
                                    index
                                  )
                                }
                              />
                            )}
                          </Field>
                        </Col>
                        <Col
                          md={4}
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            gap: 12,
                          }}
                        >
                          <div>
                            <Field name={`paymentItems[${index}].typeDiscount`}>
                              {({ input, meta }) => (
                                <SelectField
                                  placeholder="Pilih Tipe Diskon"
                                  onBlur={input.onBlur}
                                  error={meta.touched && meta.error}
                                  value={
                                    paymentData.paymentItems[index]
                                      ?.typeDiscount
                                  }
                                  data={typeDiscount}
                                  labelKey="label"
                                  valueKey="value"
                                  disabled={disabledForm}
                                  onChange={(value) =>
                                    handleChangeForm(
                                      "paymentItems",
                                      value,
                                      "typeDiscount",
                                      index
                                    )
                                  }
                                />
                              )}
                            </Field>
                          </div>
                          <div>
                            <Field name={`paymentItems[${index}].discount`}>
                              {({ input, meta }) => (
                                <InputField
                                  placeholder="Masukkan Diskon"
                                  type="number"
                                  onBlur={input.onBlur}
                                  error={meta.touched && meta.error}
                                  disabled={
                                    !field?.typeDiscount || disabledForm
                                  }
                                  value={
                                    paymentData.paymentItems[index]?.discount
                                  }
                                  onChange={(value) =>
                                    handleChangeForm(
                                      "paymentItems",
                                      value,
                                      "discount",
                                      index
                                    )
                                  }
                                />
                              )}
                            </Field>
                          </div>

                          <Button
                            variant="danger"
                            className={cx(
                              fields.value.length > 1 ? "visible" : "invisible",
                              "mb-2"
                            )}
                            onClick={() => handleDeletePaymentItem(index)}
                            disabled={disabledForm}
                          >
                            <i class="fas fa-trash"></i>
                          </Button>
                          <Button
                            className={cx(
                              index === fields.value.length - 1 ||
                                (fields.value.length === 1 && index === 0)
                                ? "visible"
                                : "invisible",
                              "mb-2"
                            )}
                            disabled={disabledForm}
                            onClick={handleAddPaymentItem}
                          >
                            <i class="fas fa-plus"></i>
                          </Button>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
              </FieldArray>
            </div>
            <hr className="m-0" />
            <div className="d-flex flex-column gap-4 align-items-end">
              <div className="d-flex flex-column gap-2">
                {paymentData?.paymentItems.map(
                  (item) =>
                    item.discount && (
                      <div
                        className={cx(
                          styles.wrapperDiscountItem,
                          "d-flex align-items-center gap-4 justify-content-between"
                        )}
                      >
                        <p>Diskon Item {item.item?.label} :</p>
                        {item.typeDiscount === "NUMBER" ? (
                          <p>{formatNumberWithCurrency(-item.discount)}</p>
                        ) : (
                          <p>{item.discount}%</p>
                        )}
                      </div>
                    )
                )}
              </div>
              <div className={styles.wrapperTotalAmountSuccess}>
                <p>Total Pembayaran</p>
                <p>{formatNumberWithCurrency(totalAmount)}</p>
              </div>

              <div className="d-flex justify-content-end gap-4">
                {isCancel && (
                  <Button
                    label={localize.getText("cancel")}
                    onClick={onClickCancel}
                    variant="danger"
                  />
                )}
                {!disabledForm && (
                  <Button
                    label={localize.getText("save")}
                    onClick={handleSubmit}
                    loading={loading}
                  />
                )}
              </div>
            </div>
          </div>
        </WrapperContent>
      )}
    </Form>
  );
}
