import axios from "axios";
import { PER_PAGE_SIZE } from "../../utils/constants";

export const postCategoryItem = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_LAMBDA_URL + `/v1/categories-item`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const putCategoryItem = async (data) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_LAMBDA_URL + `/v1/categories-item/${data.id}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCategoryItems = async ({
  page = 1,
  size = PER_PAGE_SIZE,
  businessId,
}) => {
  try {
    let link =
      process.env.REACT_APP_LAMBDA_URL + `/v1/categories-item/${businessId}?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);

    const response = await axios.get(link);

    return response;
  } catch (error) {
    return error;
  }
};

export const getCategoryItemDetail = async (id) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_LAMBDA_URL + `/v1/categories-item/detail/${id}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCategoryItem = async (id) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_LAMBDA_URL + `/v1/categories-item/${id}`
    );

    return response;
  } catch (error) {
    return error;
  }
};
