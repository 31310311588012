import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useHistory, useLocation } from "react-router-dom";
import { getParams } from "../../../../../utils/utils";
import { postSaveVisit } from "../../../../../services/AssesmentService";
import { getActiveVisitSingle } from "../../../../../services/ActiveVisitService";
import AssesmentComponent from "../Component";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { retrieveUrlFile } from "../../../../../utils/utils";
import useCanvasAnatomi from "../../../../../hooks/useCanvasAnatomi";

export default function Assesment() {
  const {
    canvasRef,
    dataAnatomis,
    activeAnnotation,
    positionAnnotation,
    handleSetPosition,
    setSelectedImage,
    handleAddPosition,
    setClickLayer,
    clickLayer,
    activeType,
    selectedImage,
    handleChangeType,
    handleUndo,
    setPossitionAnnotation,
    summaryAnnotation,
  } = useCanvasAnatomi();

  const notification = useNotification();
  const localize = useLocalization();
  const history = useHistory();
  const { state } = useLocation();
  const id = getParams("id");
  const patientId = getParams("patientId");

  const defaultInitialState = {
    anamnesia: "",
    examination: "",
    treatment: "",
    riwayatPenyakit: "",
    riwayatPenyakitKeluarga: "",
    diagnosis: [],
    allergies: [],
    fileUploads: [],
    patientId: null,
    satuSehatEncounterId: null,
  };

  const [initialValues, setInitialValues] = useState(defaultInitialState);

  const { refetch } = useQuery(
    ["ActiveVisitSingle", id],
    () => getActiveVisitSingle(id),

    {
      onSuccess: (res) => {
        const response = res.data;
        if (response) {
          setInitialValues({
            anamnesia: response.anamnesis,
            historyOfIllness: response?.riwayatPenyakit,
            familyHistoryOfDisease: response?.riwayatPenyakitKeluarga,
            diagnosis: response.diagnosisJson || [],
            allergies: response.allergies || [],
            fileUploads: response?.fileUploads || [],
            treatment: response.treatment,
            satuSehatEncounterId: response.satuSehatEncounterId,
            patientId: response?.patient.id,
          });
        }
        setPossitionAnnotation(res?.data?.annotationJson || []);
        if (res?.data?.patient?.gender) {
          handleChangeType(res.data.patient.gender);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: isLoadingSave, mutate: mutateSaveVisit } = useMutation(
    postSaveVisit,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          refetch();
          notification.success(localize.getText("successAssesment"));
          history.goBack();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  useEffect(() => {
    const titlePage = document.getElementById("title-page");
    if (state?.isView) {
      titlePage.innerText = "Visit";
    }
  }, [state]);

  const handleOnChange = (name, value) => {
    setInitialValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitForm = async (value) => {
    const fileUploads = [];

    if (value?.fileUploads) {
      await Promise.all(
        Array.from(value.fileUploads).map(async (file) => {
          let imageUrl = file;
          if (file && file.constructor === File) {
            imageUrl = await retrieveUrlFile(file);
          }
          fileUploads.push(imageUrl);
        })
      );
    }

    const diagnosisDTOs = value.diagnosis.map((item) => ({
      ...item,
      ...(item.__isNew__ ? {} : { __isNew__: false }),
    }));

    const alergiesDTOs = value.allergies.map((item) => ({
      ...item,
      ...(item.__isNew__ ? {} : { __isNew__: false }),
    }));

    const data = {
      anamnesis: value.anamnesia,
      examination: value.examination,
      treatment: value.treatment,
      riwayatPenyakit: value.historyOfIllness,
      riwayatPenyakitKeluarga: value.familyHistoryOfDisease,
      diagnosisDTOs,
      allergies: alergiesDTOs,
      annotationDTOs: positionAnnotation,
      fileUploads,
      patientId: patientId || null,
    };

    mutateSaveVisit(data);
  };

  return (
    <AssesmentComponent
      summaryAnnotation={summaryAnnotation}
      canvasRef={canvasRef}
      dataAnatomis={dataAnatomis}
      activeAnnotation={activeAnnotation}
      handleSetPosition={handleSetPosition}
      setSelectedImage={setSelectedImage}
      handleAddPosition={handleAddPosition}
      setClickLayer={setClickLayer}
      clickLayer={clickLayer}
      activeType={activeType}
      selectedImage={selectedImage}
      handleChangeType={handleChangeType}
      handleUndo={handleUndo}
      handleSubmitForm={handleSubmitForm}
      isLoadingSave={isLoadingSave}
      initialValues={initialValues}
      handleOnChange={handleOnChange}
    />
  );
}
