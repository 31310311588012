import React from "react";
import classNames from "classnames";
import {
  OverlayTrigger,
  Tooltip,
  Button as ButtonCustom,
} from "react-bootstrap";

export default function Button({
  label,
  onClick,
  variant = "primary",
  fullWidth = false,
  type = "button",
  loading = false,
  disabled = false,
  size,
  children,
  tooltip = "",
  style,
  className,
  leftIcon,
}) {
  return (
    <OverlayTrigger
      placement="top"
      trigger={["hover", "focus"]}
      overlay={
        tooltip ? <Tooltip id="button-tooltip-2">{tooltip}</Tooltip> : <p></p>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <ButtonCustom
          ref={ref}
          variant={variant}
          fullWidth={fullWidth}
          size={size}
          type={type}
          onClick={onClick}
          disabled={loading || disabled}
          {...triggerHandler}
          className={classNames(
            leftIcon ? "gap-2" : "gap-3",
            `d-flex justify-content-center align-items-center p-2 ${
              fullWidth && "btn-block"
            }`,
            className
          )}
          style={{ ...(!children && { minWidth: 120 }), ...style }}
        >
          {loading && (
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {((!label && !loading) || label) && children}
          {leftIcon && leftIcon}
          {label}
        </ButtonCustom>
      )}
    </OverlayTrigger>
  );
}
