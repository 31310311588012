import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import HistoryPaymentComponent from "../components";
import { PER_PAGE_SIZE, statusReservation } from "../../../../utils/constants";
import {
  getFilteredTime,
  downloadBlob,
  getCurrentUtc,
} from "../../../../utils/utils";
import {
  getHistoryPayment,
  postSavePayment,
  exportHistoryPayment,
} from "../../../../services/Handlers/PaymentService";
import { getCategoryItems } from "../../../../services/Handlers/MasterCategoryItemService";
import { useNotification } from "../../../../context/NotificationProvider";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import { useHome } from "../../../../context/HomeProvider";
import { useAuth } from "../../../../context/AuthProvider";
import { defaultDetailReservation } from "../../Reservation/container";
import { getDetailReservation } from "../../../../services/Handlers/ReservationService";
import { useModal } from "../../../../context/ModalProvider";

const defaultFilter = {
  type: { label: "Harian", value: "day" },
  from: moment().startOf("month").format("YYYY-MM-DD"),
  to: moment().endOf("month").format("YYYY-MM-DD"),
  page: 0,
  size: PER_PAGE_SIZE,
  category_item_id: null,
  doctor_id: null,
  method: null,
  paymentType: null,
  busines_id: null,
  current_utc: null,
};

export default function HistoryPayment() {
  const currentUtc = getCurrentUtc();
  const notification = useNotification();
  const localize = useLocalization();
  const modal = useModal();
  const { dataDoctors, isLoadingDoctor } = useHome();
  const { userDetail } = useAuth();

  const [totalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [dataHistoryPayment, setDataHistoryPayment] = useState([]);
  const [filterHistory, setFilterHistory] = useState(
    JSON.parse(JSON.stringify(defaultFilter))
  );
  const [dataCategoryItem, setDataCategoryItem] = useState([]);

  const [dataDetailReservation, setDataDetailReservation] = useState(
    defaultDetailReservation
  );
  const [showModalDetail, setShowModalDetail] = useState(null);
  const [showModalAddPayment, setShowModalAddPayment] = useState(false);

  const { isLoading, mutate: mutateHistory } = useMutation(
    ["GetHistoryPayment"],
    (data) => getHistoryPayment(data),
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          if (res?.data?.data.length > 0) {
            const historyPayment = res?.data.data.map((item) => ({
              ...item,
              doctor_name:
                dataDoctors.find((value) => value.id === item.doctor_id)
                  ?.name || "",
            }));
            setDataHistoryPayment(historyPayment);
          } else {
            setDataHistoryPayment([]);
          }
          setTotalEntries(res?.data?.total);
          setTotalPage(Math.ceil(res?.data?.total / filterHistory.size));
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
    }
  );

  const { isFetching: isLoadingCategory } = useQuery(
    ["CategoryItemList", userDetail?.businessId],
    () =>
      getCategoryItems({
        businessId: userDetail?.businessId,
        page: 1,
        size: 99999,
      }),
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          setDataCategoryItem(res?.data.data || []);
        }
      },
      enabled: !!userDetail?.businessId,
    }
  );

  const { isLoading: isLoadingSubmitPayment, mutate: mutateSubmitPayment } =
    useMutation(postSavePayment, {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          mutateHistory({
            ...filterHistory,
            current_utc: currentUtc,
            busines_id: userDetail.businessId,
          });
          notification.success(localize.getText("successAddPayment"));
          handleClearAllFormModal();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    });

  const { isLoading: isLoadingDetail, isFetching: isFetchingDetail } = useQuery(
    ["DetailReservation", showModalDetail],
    () => getDetailReservation(showModalDetail),
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          if (response) {
            const status = statusReservation.find(
              (item) => item.value === response.status
            );
            const reservationTimeFormat = getFilteredTime(
              response.duration_visit,
              response.time_slots
            )[0];
            const reservationTime =
              moment(
                reservationTimeFormat.availability_start,
                "HH:mm:ss"
              ).format("HH:mm") +
              " - " +
              moment(reservationTimeFormat.availability_end, "HH:mm:ss").format(
                "HH:mm"
              );

            setDataDetailReservation((prevState) => ({
              ...prevState,
              id: response.id,
              patientId: response.patient_id,
              reservationDate: moment(
                response.reservation_date,
                "YYYY-MM-DD"
              ).format("dddd DD MMMM YYYY"),
              reservationTime,
              durationVisit: response.duration_visit,
              doctorName:
                dataDoctors.find((value) => value.id === response.doctor_id)
                  ?.name || "",
              doctorId: response.doctor_id,
              status,
              noSequence: response.no_sequence,
              identityNumber: response.user_identity_number,
              identityType: response.user_identity_type,
              name: response.user_name,
              phoneNumber: response.user_phone_number,
              dateOfBirth: moment(
                response.user_date_of_birth,
                "YYYY-MM-DD"
              ).format("DD MMMM YYYY"),
              gender: response.user_gender,
              payment: response.payment,
            }));
          }
        } else {
          notification.error(localize.getText("unknownMessageError"));
        }
      },
      enabled: !!showModalDetail,
    }
  );

  const { isLoading: isLoadingExport, mutate: mutateExportHistory } =
    useMutation(
      ["ExportHistoryPayment"],
      (data) => exportHistoryPayment(data),
      {
        onSuccess: (res) => {
          if (res.status === 200 || res.status === 201) {
            downloadBlob(res.data, "history-payment");
          } else {
            notification.error(
              res?.data?.detail || localize.getText("unknownMessageError")
            );
          }
        },
      }
    );

  useEffect(() => {
    if (userDetail?.businessId) {
      mutateHistory({
        ...filterHistory,
        busines_id: userDetail.businessId,
        current_utc: currentUtc,
      });
    }
    // eslint-disable-next-line
  }, [userDetail]);

  const handleChangeFilter = async (name, value) => {
    if (name === "type") {
      await setFilterHistory((prevState) => ({
        ...prevState,
        from: null,
        to: null,
      }));
    } else if (
      name === "from" &&
      filterHistory.to &&
      moment(value, "YYYY-MM-DD").isAfter(
        moment(filterHistory.to, "YYYY-MM-DD")
      )
    ) {
      await setFilterHistory((prevState) => ({
        ...prevState,
        to: null,
      }));
    }
    setFilterHistory((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClickFilter = () => {
    handleChangeFilter("page", 0);
    mutateHistory({
      ...filterHistory,
      page: 0,
      method: filterHistory?.method?.value,
      category_item_id: filterHistory?.category_item_id?.value,
      doctor_id: filterHistory?.doctor_id?.value,
      paymentType: filterHistory?.paymentType?.value,
      busines_id: userDetail.businessId,
      current_utc: currentUtc,
    });
  };

  const handleChangeSizePerPage = (size) => {
    handleChangeFilter("size", size);
    handleChangeFilter("page", 0);
    mutateHistory({
      ...filterHistory,
      size,
      page: 0,
      method: filterHistory?.method?.value,
      category_item_id: filterHistory?.category_item_id?.value,
      doctor_id: filterHistory?.doctor_id?.value,
      paymentType: filterHistory?.paymentType?.value,
      busines_id: userDetail.businessId,
      current_utc: currentUtc,
    });
  };

  const handlePagination = (value) => {
    handleChangeFilter("page", value);
    mutateHistory({
      ...filterHistory,
      page: value + 1,
      method: filterHistory?.method?.value,
      category_item_id: filterHistory?.category_item_id?.value,
      doctor_id: filterHistory?.doctor_id?.value,
      paymentType: filterHistory?.paymentType?.value,
      busines_id: userDetail?.businessId,
      current_utc: currentUtc,
    });
  };

  const handleClearFilter = async () => {
    await setFilterHistory({
      ...defaultFilter,
      current_utc: currentUtc,
      type: filterHistory.type,
      from: null,
      to: null,
    });
    mutateHistory(defaultFilter);
  };

  const handleHideShowModalDetial = (value) => {
    if (!value) {
      setDataDetailReservation(defaultDetailReservation);
    }
    setShowModalDetail(value);
  };

  const handleClearAllFormModal = () => {
    setDataDetailReservation(defaultDetailReservation);
    setShowModalDetail(null);
    setShowModalAddPayment(false);
    modal.dismiss();
  };

  const handleClickPayment = (value) => {
    const data = {
      bookingId: dataDetailReservation.id,
      businessId: userDetail.businessId,
      updatedBy: userDetail?.firstName,
      ...value,
    };

    mutateSubmitPayment(data);
  };

  const handleClickPaymentPharmacy = (value) => {
    const data = {
      bookingId: null,
      businessId: userDetail.businessId,
      createdBy: userDetail?.firstName,
      updatedBy: userDetail?.firstName,
      ...value,
    };

    mutateSubmitPayment(data);
  };

  const handleClickExport = () => {
    mutateExportHistory({
      ...filterHistory,
      busines_id: userDetail.businessId,
      method: filterHistory?.method?.value,
      category_item_id: filterHistory?.category_item_id?.value,
      doctor_id: filterHistory?.doctor_id?.value,
      paymentType: filterHistory?.paymentType?.value,
      current_utc: currentUtc,
    });
  };

  return (
    <HistoryPaymentComponent
      dataHistoryPayment={dataHistoryPayment}
      isLoading={isLoading}
      totalPage={totalPage}
      totalEntries={totalEntries}
      activePage={filterHistory?.page}
      handlePagination={handlePagination}
      filterHistory={filterHistory}
      handleChangeFilter={handleChangeFilter}
      handleClickFilter={handleClickFilter}
      handleClearFilter={handleClearFilter}
      handleChangeSizePerPage={handleChangeSizePerPage}
      dataCategoryItem={dataCategoryItem}
      dataDoctors={dataDoctors}
      isLoadingDoctor={isLoadingDoctor}
      isLoadingCategory={isLoadingCategory}
      isLoadingExport={isLoadingExport}
      handleHideShowModalDetial={handleHideShowModalDetial}
      handleClickExport={handleClickExport}
      dataDetailReservation={dataDetailReservation}
      isLoadingModalDetail={
        isLoadingDetail || isFetchingDetail || isLoadingSubmitPayment
      }
      businessId={userDetail?.businessId}
      showModalDetail={showModalDetail}
      handleClickPayment={handleClickPayment}
      handleClickPaymentPharmacy={handleClickPaymentPharmacy}
      showModalAddPayment={showModalAddPayment}
      setShowModalAddPayment={setShowModalAddPayment}
      isLoadingSubmitPayment={isLoadingSubmitPayment}
    />
  );
}
