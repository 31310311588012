import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../../../components/Card";
import {
  optionsFilterDuration,
  paymentMethod,
  paymentTypeOptions,
} from "../../../../utils/constants";
import AsyncSelect from "../../../components/AsyncSelect";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import Table from "../../../components/Table";
import { formatNumberWithCurrency } from "../../../../utils/utils";
import ModalDetailReservation from "../../../components/ModalDetailReservation";
import Modal from "../../../components/Modal";
import FormPayment from "../../../components/FormPayment";

export default function HistoryPaymentComponent({
  dataHistoryPayment,
  isLoading = false,
  totalPage = 0,
  totalEntries = 0,
  handlePagination,
  activePage,
  filterHistory,
  handleChangeFilter,
  handleClickFilter,
  handleClearFilter,
  handleChangeSizePerPage,
  dataCategoryItem = [],
  dataDoctors = [],
  isLoadingDoctor = false,
  isLoadingCategory = false,
  isLoadingExport = false,
  handleHideShowModalDetial = () => undefined,
  handleClickExport = () => undefined,
  isLoadingModalDetail,
  dataDetailReservation,
  businessId = null,
  showModalDetail,
  handleClickPayment,
  handleClickPaymentPharmacy,
  showModalAddPayment,
  setShowModalAddPayment,
  isLoadingSubmitPayment = false,
}) {
  const localize = useLocalization();
  const formatInputDate =
    optionsFilterDuration.find(
      (item) => item.value === filterHistory.type?.value
    )?.format || "DD-MM-YYYY";

  const columns = [
    { label: localize.getText("idPayment") },
    { label: localize.getText("namePatient") },
    { label: localize.getText("doctor") },
    { label: localize.getText("nameItem") },
    { label: localize.getText("priceItem") },
    { label: localize.getText("quantity") },
    { label: localize.getText("datePayment") },
    { label: localize.getText("paymentMethod") },
    { label: localize.getText("paymentType") },
    { label: localize.getText("totalPayment") },
    {
      label: localize.getText("action"),
      className: "text-center",
    },
  ];

  return (
    <div>
      <Card title="FILTER DATA PEMBAYARAN">
        <div className="d-flex flex-column" style={{ gap: 32 }}>
          <Row style={{ rowGap: 18 }}>
            <Col md={4}>
              <AsyncSelect
                label={localize.getText("searchBy")}
                placeholder="Pilih kategori"
                options={optionsFilterDuration}
                value={filterHistory.type}
                onChange={(value) => handleChangeFilter("type", value)}
                fullWidth
                height={38}
                isBordered
              />
            </Col>
            <Col md={4}>
              <InputField
                type="date"
                views={[filterHistory.type?.value || "day"]}
                format={formatInputDate}
                label={localize.getText("from")}
                onChange={(value) => handleChangeFilter("from", value)}
                value={filterHistory.from || null}
                fullWidth
                disableHighlightToday
                isBorder
                classNameInput="date-picker-search"
              />
            </Col>
            <Col md={4}>
              <InputField
                type="date"
                views={[filterHistory.type?.value || "day"]}
                format={formatInputDate}
                label={localize.getText("to")}
                value={filterHistory.to || null}
                onChange={(value) => handleChangeFilter("to", value)}
                shouldDisableYear={(year) => {
                  return year.isBefore(filterHistory.from);
                }}
                shouldDisableMonth={(month) => {
                  return month.isBefore(filterHistory.from);
                }}
                shouldDisableDate={(date) => {
                  return date.isBefore(filterHistory.from);
                }}
                disabled={!filterHistory.from}
                fullWidth
                disableHighlightToday
                disablePast
                isEndField
                isBorder
                classNameInput="date-picker-search"
              />
            </Col>
          </Row>
          <Row style={{ rowGap: 18 }}>
            <Col md={3}>
              <AsyncSelect
                label={localize.getText("doctor")}
                placeholder={localize.getText("placeholderField", {
                  fieldName: localize.getText("doctor"),
                })}
                options={dataDoctors}
                fullWidth
                height={38}
                isBordered
                isClearable
                labelKey="name"
                valueKey="id"
                isLoading={isLoadingDoctor}
                onChange={(value) => handleChangeFilter("doctor_id", value)}
              />
            </Col>
            <Col md={3}>
              <AsyncSelect
                label={localize.getText("category-item")}
                placeholder={localize.getText("placeholderField", {
                  fieldName: localize.getText("category-item"),
                })}
                options={dataCategoryItem}
                fullWidth
                height={38}
                isBordered
                isClearable
                labelKey="name"
                valueKey="id"
                isLoading={isLoadingCategory}
                onChange={(value) =>
                  handleChangeFilter("category_item_id", value)
                }
              />
            </Col>
            <Col md={3}>
              <AsyncSelect
                label={localize.getText("paymentMethod")}
                placeholder={localize.getText("placeholderField", {
                  fieldName: localize.getText("paymentMethod"),
                })}
                options={paymentMethod}
                fullWidth
                height={38}
                isBordered
                isClearable
                onChange={(value) => handleChangeFilter("method", value)}
              />
            </Col>
            <Col md={3}>
              <AsyncSelect
                label={localize.getText("paymentType")}
                placeholder={localize.getText("placeholderField", {
                  fieldName: localize.getText("paymentType"),
                })}
                options={paymentTypeOptions}
                fullWidth
                height={38}
                isBordered
                isClearable
                onChange={(value) => handleChangeFilter("paymentType", value)}
              />
            </Col>
          </Row>
          <div className="d-flex gap-4 justify-content-end">
            <Button
              label={localize.getText("search")}
              onClick={handleClickFilter}
              disabled={!(filterHistory.from && filterHistory.to)}
            />
            <Button
              leftIcon={<i className="far fa-file-excel"></i>}
              label="Export"
              variant="info"
              loading={isLoadingExport}
              onClick={handleClickExport}
            />
            <Button
              label={localize.getText("reset")}
              variant="danger"
              onClick={handleClearFilter}
            />
          </div>
        </div>
      </Card>
      <Card
        header={
          <div className="d-flex justify-content-end w-100">
            <Button
              label="Tambah Pembayaran"
              onClick={() => setShowModalAddPayment(!showModalAddPayment)}
            />
          </div>
        }
      >
        <Table
          columns={columns}
          loading={isLoading}
          activePage={activePage}
          totalPage={totalPage}
          totalShow={dataHistoryPayment.length}
          totalEntries={totalEntries}
          handlePagination={handlePagination}
          sizePerPage={filterHistory.size}
          handleSizePerPage={handleChangeSizePerPage}
        >
          {dataHistoryPayment.map((item, index) => (
            <tr key={index}>
              <td className="sorting_1">{item?.payment_id || "-"}</td>
              <td>{item?.user_name || "-"}</td>
              <td>{item.doctor_name || "-"}</td>
              <td>{item.name_item}</td>
              <td>{formatNumberWithCurrency(item.amount_item)}</td>
              <td>{item.quantity}</td>
              <td>{item.payment_date}</td>
              <td>{item.payment_method}</td>
              <td>{item.payment_type}</td>
              <td>{formatNumberWithCurrency(item.total_amount)}</td>
              <td>
                {item.id && (
                  <div className="d-flex justify-content-center gap-3">
                    <Button
                      variant="success"
                      tooltip="Detail Pembayaran"
                      onClick={() => handleHideShowModalDetial(item.id)}
                    >
                      <i class="fas fa-money-bill-wave"></i>
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </Table>
      </Card>
      <Modal
        visible={showModalAddPayment}
        size="lg"
        dialogClassName="max-w-1200"
        handleHideModal={() => setShowModalAddPayment(!showModalAddPayment)}
      >
        <FormPayment
          title="Tambah Pembayaran"
          businessId={businessId}
          onClickCancel={() => setShowModalAddPayment(!showModalAddPayment)}
          onSubmit={handleClickPaymentPharmacy}
          loading={isLoadingSubmitPayment}
          isFieldUser
          isCancel
        />
      </Modal>
      <ModalDetailReservation
        isPaymentSection
        loading={isLoadingModalDetail}
        businessId={businessId}
        dataDetailReservation={dataDetailReservation}
        show={showModalDetail}
        handleModal={handleHideShowModalDetial}
        handleClickPayment={handleClickPayment}
      />
    </div>
  );
}
