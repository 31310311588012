import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { ThemeContext } from "../../../../context/ThemeContext";
import { useAuth } from "../../../../context/AuthProvider";
import styles from "./Navbar.module.css";
import Button from "../../../components/Button";
import { logoImage } from "../../../../utils/constants";
import HeaderProfile from "../../../components/HeaderProfile";
import { getCSSVariable } from "../../../../utils/utils";

export default function Navbar({
  dataLink = [],
  isAction = false,
  isReservation = false,
  clinicLogo,
}) {
  const [isShowNav, setIsShowNav] = useState(false);
  const { isAuthenticated } = useAuth();
  const { windowWidth } = useContext(ThemeContext);
  const isMobile = windowWidth <= 768;
  const primaryColor = getCSSVariable("--primary");

  useEffect(() => {
    if (isShowNav && isMobile) {
      document.body.style.overflowY = "hidden";
      return;
    }

    document.body.style.overflowY = "scroll";
  }, [isShowNav, isMobile]);

  const handleClickLink = () => {
    document.body.style.overflowY = "scroll";
    setIsShowNav(false);
  };

  return (
    <div
      className={
        isReservation ? styles.navbarReservationPosition : styles.navbarPosition
      }
    >
      <div
        className={
          isReservation ? styles.navbarReservationWrapper : styles.navbarWrapper
        }
      >
        <div
          className={styles.navbarWrapperLogo}
          style={
            !isAuthenticated && isMobile && !isReservation
              ? { width: "100%" }
              : {}
          }
        >
          <Link to="/">
            <img
              alt="sistem-klinik"
              src={logoImage.main}
              className={styles.navbarLogo}
            />
          </Link>
          {!isReservation && isMobile && (
            <div
              className={`hamburger ${isShowNav ? "is-active" : ""}`}
              onClick={() => setIsShowNav(!isShowNav)}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          )}
        </div>

        {!isReservation ? (
          <>
            {!isMobile && (
              <div className={styles.navbarLink}>
                {dataLink.map((item, index) =>
                  item.isLink ? (
                    <Link to={item.url} passHref>
                      <a
                        key={index}
                        href="/"
                        style={item.isActive ? { color: primaryColor } : {}}
                      >
                        {item.label}
                      </a>
                    </Link>
                  ) : (
                    <a
                      href={item.url}
                      key={index}
                      style={item.isActive ? { color: primaryColor } : {}}
                    >
                      {item.label}
                    </a>
                  )
                )}
              </div>
            )}

            {isAction && (
              <div className={styles.navbarAction}>
                {isAuthenticated ? (
                  <HeaderProfile />
                ) : (
                  !isMobile && (
                    <>
                      <Link to="/login">
                        <Button label="Masuk" />
                      </Link>
                      <Link to="/register">
                        <Button label="Daftar" variant="outline-primary" />
                      </Link>
                    </>
                  )
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {clinicLogo && (
              <img
                className={styles.imageClinic}
                src={clinicLogo || "https://dummyimage.com/70x70/000/fff"}
                alt=""
              />
            )}
          </>
        )}
      </div>
      {isMobile && !isReservation && (
        <div
          className={classNames(
            styles.navbarMobileWrapper,
            isShowNav && styles.showNavbarMobile
          )}
        >
          {isMobile && (
            <>
              <div className={styles.navbarLink}>
                {dataLink.map((item, index) =>
                  item.isLink ? (
                    <Link to={item.url} passHref>
                      <a
                        key={index}
                        href="/"
                        onClick={handleClickLink}
                        style={item.isActive ? { color: primaryColor } : {}}
                      >
                        {item.label}
                      </a>
                    </Link>
                  ) : (
                    <a
                      href={item.url}
                      key={index}
                      onClick={handleClickLink}
                      style={item.isActive ? { color: primaryColor } : {}}
                    >
                      {item.label}
                    </a>
                  )
                )}
              </div>
              {isAction && !isAuthenticated && (
                <div className={styles.navbarMobileAction}>
                  <Link to="/login">
                    <Button
                      label="Masuk"
                      fullWidth
                      className={styles.navbarMobileButton}
                      onClick={handleClickLink}
                    />
                  </Link>
                  <Link to="/register">
                    <Button
                      label="Daftar"
                      variant="outline-primary"
                      fullWidth
                      className={styles.navbarMobileButton}
                      onClick={handleClickLink}
                    />
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
