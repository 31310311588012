import React from "react";
import classNames from "classnames";
import { Form, Field } from "react-final-form";
import styles from "./FormStafClinicComponent.module.css";
import { useLocalization } from "../../../../context/LocalizeContextProvider";

import { validEmail, Gender, roleUsers } from "../../../../utils/constants";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import RadioField from "../../../components/RadioField";
import TitlePage from "../../../components/TitlePage";
import AsyncSelect from "../../../components/AsyncSelect";

export default function FormStafClinicComponent({
  handleSubmitForm = () => undefined,
  isLoadingSubmit = false,
  isEdit = false,
  isView = false,
  initialValues,
}) {
  const localize = useLocalization();

  return (
    <div className="card">
      <div className={classNames("card-body", styles.wrapper)}>
        <TitlePage
          text={
            (isEdit
              ? localize.getText("edit")
              : isView
              ? localize.getText("detail")
              : localize.getText("add")) +
            " " +
            localize.getText("userClinic")
          }
          isBack
        />
        <Form
          onSubmit={handleSubmitForm}
          initialValues={{
            name: initialValues?.name,
            email: initialValues?.email,
            phoneNumber: initialValues?.userPhoneNumber,
            dateOfBirth: initialValues?.userDob,
            gender: initialValues?.userGender,
            address: initialValues?.userAddress,
            identificationNumber: initialValues?.identificationNumber,
            role:
              isView || isEdit
                ? roleUsers.find((item) => item.id === initialValues?.role)
                : initialValues?.role,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = localize.getText("generalRequiredFieldValidation", {
                fieldName: localize.getText("name"),
              });
            }
            if (!values.email) {
              errors.email = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("email") }
              );
            }
            if (values.email && !values.email.match(validEmail)) {
              errors.email = localize.getText("notValidField", {
                fieldName: localize.getText("email"),
              });
            }
            if (!values.role) {
              errors.role = localize.getText("generalRequiredFieldValidation", {
                fieldName: localize.getText("role"),
              });
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("phoneNumber") }
              );
            }
            if (!values.dateOfBirth) {
              errors.dateOfBirth = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("dateOfBirth") }
              );
            }
            if (!values.gender) {
              errors.gender = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("gender") }
              );
            }
            if (!values.address) {
              errors.address = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("address") }
              );
            }
            if (!values.identificationNumber) {
              errors.identificationNumber = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("identityNumber") }
              );
            }
            if (!isEdit && !values.password) {
              errors.password = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("password") }
              );
            }
            if (!isEdit && !values.confirmPassword) {
              errors.confirmPassword = localize.getText(
                "generalRequiredFieldValidation",
                { fieldName: localize.getText("confirmPassword") }
              );
            }

            if (
              values.password &&
              values.confirmPassword &&
              values.password !== values.confirmPassword
            ) {
              errors.password = localize.getText("passwordNotSameField");
            }

            return errors;
          }}
        >
          {({ handleSubmit }) => (
            <>
              <form className={styles.wrapperForm} onSubmit={handleSubmit}>
                {initialValues?.practitionerId && (
                  <InputField
                    label={localize.getText("practitionerId")}
                    disabled={true}
                    value={initialValues.practitionerId}
                  />
                )}

                <Field name="name">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("name")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("name"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                    />
                  )}
                </Field>
                <Field name="email">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("email")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("email"),
                      })}
                      type="email"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                    />
                  )}
                </Field>
                <Field name="role">
                  {({ input, meta }) => (
                    <AsyncSelect
                      label="Role"
                      options={roleUsers}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("role"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                      labelKey="label"
                      valueKey="id"
                    />
                  )}
                </Field>
                <Field name="phoneNumber">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("phoneNumber")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("phoneNumber"),
                      })}
                      type="number"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      disabled={isView}
                      value={input.value}
                    />
                  )}
                </Field>

                <Field name="dateOfBirth">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("dateOfBirth")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("dateOfBirth"),
                      })}
                      type="date"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                      disabled={isView}
                    />
                  )}
                </Field>
                <Field name="gender">
                  {({ input, meta }) => (
                    <RadioField
                      label={localize.getText("gender")}
                      datas={Gender()}
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.touched && meta.error}
                      disabled={isView}
                    />
                  )}
                </Field>
                <Field name="address">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("address")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("address"),
                      })}
                      type="textarea"
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                      disabled={isView}
                    />
                  )}
                </Field>

                <Field name="identificationNumber">
                  {({ input, meta }) => (
                    <InputField
                      label={localize.getText("identityNumber")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("identityNumber"),
                      })}
                      error={meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input.value}
                      disabled={isView}
                    />
                  )}
                </Field>

                {!(isEdit || isView) && (
                  <>
                    <Field name="password">
                      {({ input, meta }) => (
                        <InputField
                          label={localize.getText("password")}
                          placeholder={localize.getText("placeholderField", {
                            fieldName: localize.getText("password"),
                          })}
                          type="password"
                          error={meta.touched && meta.error}
                          onChange={(value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          disabled={isView}
                          value={input.value}
                        />
                      )}
                    </Field>

                    <Field name="confirmPassword">
                      {({ input, meta }) => (
                        <InputField
                          label={localize.getText("confirmPassword")}
                          placeholder={localize.getText("placeholderField", {
                            fieldName: localize.getText("confirmPassword"),
                          })}
                          type="password"
                          error={meta.touched && meta.error}
                          onChange={(value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          disabled={isView}
                          value={input.value}
                        />
                      )}
                    </Field>
                  </>
                )}

                {!isView && (
                  <Button
                    label={localize.getText("save")}
                    style={{ width: 180, marginTop: 42 }}
                    type="submit"
                    loading={isLoadingSubmit}
                  />
                )}
              </form>
            </>
          )}
        </Form>
      </div>
    </div>
  );
}
