import React, { useRef, useEffect, useState } from "react";
import cx from "classnames";
import moment from "moment";
import { Field } from "react-final-form";

import styles from "./CalendarScheduleDoctor.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputField from "../InputField";
import Table from "../Table";
import { useLocalization } from "../../../context/LocalizeContextProvider";
import {
  Gender,
  statusReservation,
  TypeIdentity,
} from "../../../utils/constants";
import RadioField from "../RadioField";
import RadioSingleField from "../RadioSingleField";
import Spinner from "../Spinner";
import TextStatusReservation from "../TextStatusReservation";
import AsyncSelect from "../AsyncSelect";
import usePatient from "../../../hooks/usePatient";
import FilterPatient from "../FilterPatient";
import PillItem from "../PillItem";
import { formatOptionsToArray } from "../../../utils/utils";

export const FormNewPatient = ({ value, handleChangeForm }) => {
  const localize = useLocalization();
  return (
    <>
      <div className="col-md-12">
        <div>
          <Field name="identificationType">
            {({ input, meta }) => (
              <RadioField
                label={localize.getText("identity")}
                datas={TypeIdentity()}
                value={value.identificationType}
                onChange={(value) =>
                  handleChangeForm("identificationType", value)
                }
                onBlur={input.onBlur}
                error={meta.touched && meta.error}
              />
            )}
          </Field>
          <Field name="identificationNumber">
            {({ input, meta }) => (
              <InputField
                placeholder={localize.getText("placeholderField", {
                  fieldName:
                    value.identificationType === "KTP"
                      ? localize.getText("numberKtp")
                      : localize.getText("numberPassport"),
                })}
                error={meta.touched && meta.error}
                onChange={(value) =>
                  handleChangeForm("identificationNumber", value)
                }
                onBlur={input.onBlur}
                value={value.identificationNumber}
              />
            )}
          </Field>
        </div>
      </div>
      <div className="col-md-12">
        <Field name="name">
          {({ input, meta }) => (
            <InputField
              label={localize.getText("name")}
              placeholder={localize.getText("placeholderField", {
                fieldName: localize.getText("name"),
              })}
              error={meta.touched && meta.error}
              value={value.name}
              onBlur={input.onBlur}
              onChange={(value) => handleChangeForm("name", value)}
              fullWidth
            />
          )}
        </Field>
      </div>
      <div className="col-md-6">
        <Field name="phoneNumber">
          {({ input, meta }) => (
            <InputField
              label={localize.getText("phoneNumber")}
              placeholder={localize.getText("placeholderField", {
                fieldName: localize.getText("phoneNumber"),
              })}
              error={meta.touched && meta.error}
              value={value.phoneNumber}
              onBlur={input.onBlur}
              onChange={(value) => handleChangeForm("phoneNumber", value)}
              type="number"
              fullWidth
            />
          )}
        </Field>
      </div>
      <div className="col-md-6">
        <Field name="email">
          {({ input, meta }) => (
            <InputField
              label={localize.getText("email")}
              placeholder={localize.getText("placeholderField", {
                fieldName: localize.getText("email"),
              })}
              error={meta.touched && meta.error}
              value={value.email}
              onBlur={input.onBlur}
              onChange={(value) => handleChangeForm("email", value)}
              fullWidth
            />
          )}
        </Field>
      </div>
      <div className="col-md-6">
        <Field name="dateOfBirth">
          {({ input, meta }) => (
            <InputField
              type="date"
              label={localize.getText("dateOfBirth")}
              error={meta.touched && meta.error}
              value={value.dateOfBirth}
              onChange={(value) => handleChangeForm("dateOfBirth", value)}
              fullWidth
            />
          )}
        </Field>
      </div>
      <div className="col-md-6">
        <Field name="gender">
          {({ input, meta }) => (
            <RadioField
              label={localize.getText("gender")}
              datas={Gender()}
              value={value.gender}
              onChange={(value) => handleChangeForm("gender", value)}
              onBlur={input.onBlur}
              error={meta.touched && meta.error}
            />
          )}
        </Field>
      </div>
      <div className="col-md-6">
        <Field name="medicalRecordNumber">
          {({ input, meta }) => (
            <InputField
              label={localize.getText("medicalRecord")}
              placeholder={localize.getText("placeholderField", {
                fieldName: localize.getText("medicalRecord"),
              })}
              error={meta.touched && meta.error}
              onChange={(value) =>
                handleChangeForm("medicalRecordNumber", value)
              }
              onBlur={input.onBlur}
              value={value.medicalRecordNumber}
            />
          )}
        </Field>
      </div>
      <div className="col-md-6">
        <Field name="allergies">
          {({ input, meta }) => (
            <AsyncSelect
              label={localize.getText("alergies")}
              placeholder="Select Alergies..."
              options={value.allergies}
              isMulti
              isCreatable
              error={meta.touched && meta.error}
              onChange={(value) => handleChangeForm("allergies", value)}
              onBlur={input.onBlur}
              value={value.allergies}
            />
          )}
        </Field>
      </div>
      <div className="col-md-6">
        <Field name="address">
          {({ input, meta }) => (
            <InputField
              label={localize.getText("address")}
              placeholder={localize.getText("placeholderField", {
                fieldName: localize.getText("address"),
              })}
              type="textarea"
              error={meta.touched && meta.error}
              onChange={(value) => handleChangeForm("address", value)}
              onBlur={input.onBlur}
              value={value.address}
            />
          )}
        </Field>
      </div>
      <div className="col-md-6">
        <Field name="remark">
          {({ input, meta }) => (
            <InputField
              label={localize.getText("remark")}
              placeholder={localize.getText("placeholderField", {
                fieldName: localize.getText("remark"),
              })}
              type="textarea"
              error={meta.touched && meta.error}
              onChange={(value) => handleChangeForm("remark", value)}
              onBlur={input.onBlur}
              value={value.remark}
            />
          )}
        </Field>
      </div>
    </>
  );
};

export const ExistingPatient = ({
  columnsExisting,
  selectedExistingPatient,
  setSelectedExistingPatient,
  defaultForm = null,
}) => {
  const localize = useLocalization();
  const {
    page,
    totalPage,
    totalEntries,
    dataPatient,
    isLoading,
    isRefetching,
    sizePerPage,
    setSizePerPage,
    handlePagination,
    handleSetFilter,
  } = usePatient();

  useEffect(() => {
    setSizePerPage(5);

    // eslint-disable-next-line
  }, []);
  const colorsAllergys = ["bg-blue", "bg-orange", "bg-danger"];
  return (
    <div className="d-flex flex-column gap-4">
      <FilterPatient
        handleSetFilter={handleSetFilter}
        isNoReset
        defaultForm={defaultForm}
      />
      <Table
        columns={columnsExisting}
        loading={isLoading || isRefetching}
        activePage={page}
        totalPage={totalPage}
        totalShow={dataPatient?.length}
        totalEntries={totalEntries}
        handlePagination={handlePagination}
        sizePerPage={sizePerPage}
        handleSizePerPage={setSizePerPage}
        isHovered
      >
        {dataPatient?.map((item, index) => (
          <tr
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedExistingPatient(item)}
          >
            <td className="sorting_1">
              <RadioSingleField
                value={selectedExistingPatient?.id === item.id}
                onChange={() => setSelectedExistingPatient(item)}
              />
            </td>
            <td> {item?.medicalRecordNumber || "-"}</td>
            <td>{item.name}</td>
            <td>
              <InputField
                placeholder={localize.getText("placeholderField", {
                  fieldName: localize.getText("numberKtp"),
                })}
                value={
                  selectedExistingPatient?.id === item.id
                    ? selectedExistingPatient?.identificationNumber
                    : item.identificationNumber || ""
                }
                onChange={(value) =>
                  setSelectedExistingPatient({
                    ...item,
                    identificationNumber: value,
                  })
                }
                error={
                  selectedExistingPatient?.id === item.id
                    ? !selectedExistingPatient?.identificationNumber
                      ? localize.getText("generalRequiredFieldValidation", {
                          fieldName: localize.getText("numberKtp"),
                        })
                      : selectedExistingPatient?.identificationNumber.length !==
                          16 && item.identificationType === "KTP"
                      ? localize.getText("notValid16Length", {
                          fieldName: localize.getText("numberKtp"),
                        })
                      : ""
                    : ""
                }
              />
            </td>
            <td>
              <div className="d-flex flex-wrap gap-2">
                {formatOptionsToArray(item.allergy).length > 0
                  ? formatOptionsToArray(item.allergy).map((item, index) => (
                      <PillItem
                        text={item}
                        key={index}
                        bgColor={colorsAllergys[index % colorsAllergys.length]}
                      />
                    ))
                  : "-"}
              </div>
            </td>
            <td>{item.phoneNumber}</td>
            <td>{item.dob}</td>
            <td>{item.gender}</td>
            <td>{item.address}</td>
          </tr>
        ))}
      </Table>
    </div>
  );
};

export default function CalendarScheduleDoctor({
  dataReservation = [],
  reservationDate,
  setReservationDate = () => undefined,
  setShowModalAdd = () => undefined,
  setShowModalDetail = () => undefined,
  loading = false,
}) {
  const localize = useLocalization();

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [time, setTime] = useState([]);

  const generateHourlyTimes = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      let start = moment({ hour }).format("HH:mm");
      let end = moment({ hour: (hour + 1) % 24 }).format("HH:mm");
      times.push({ start, end });
    }
    return times;
  };

  const tableContainerRef = useRef(null);

  useEffect(() => {
    if (time.length === 0) {
      setTime(generateHourlyTimes());
    }
  }, [time]);

  useEffect(() => {
    if (tableContainerRef.current) {
      const thElements = tableContainerRef.current.querySelectorAll("th");
      const nineAMIndex = time.findIndex((t) => t.start === "09:00");

      if (nineAMIndex !== -1 && thElements.length > nineAMIndex) {
        const thWidth = thElements[nineAMIndex].offsetWidth;
        const scrollPosition = nineAMIndex * thWidth;
        tableContainerRef.current.scrollLeft = scrollPosition;
      }
    }
  }, [time]);

  const goToPreviousDay = () => {
    setReservationDate((prevDate) => moment(prevDate).subtract(1, "days"));
  };

  const goToNextDay = () => {
    setReservationDate((prevDate) => moment(prevDate).add(1, "days"));
  };

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
        <div className="d-flex align-items-center gap-3">
          <div className="d-flex align-items-center gap-3">
            <i
              className="fas fa-chevron-left c-pointer"
              onClick={goToPreviousDay}
            ></i>
            <p className="mb-0 h5">
              {reservationDate.format("dddd, DD MMM YYYY")}
            </p>
            <i
              className="fas fa-chevron-right c-pointer"
              onClick={goToNextDay}
            ></i>
          </div>
          <div className="position-relative">
            <i
              className="fas fa-calendar-alt h3 mb-0 c-pointer"
              onClick={() => setOpenDatePicker(!openDatePicker)}
              style={{ zIndex: 9999 }}
            ></i>
            <DatePicker
              open={openDatePicker}
              value={reservationDate}
              onChange={(value) => setReservationDate(value)}
              onClose={() => setOpenDatePicker(!openDatePicker)}
              className={styles.positionDatePicker}
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-4">
          {statusReservation.map((item, index) => (
            <TextStatusReservation
              label={item.label}
              color={item.color}
              key={index}
            />
          ))}
        </div>
      </div>
      <Spinner loading={loading}>
        <div
          style={{
            overflow: "auto",
          }}
          ref={tableContainerRef}
        >
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th className={cx(styles.columnHead, styles.columnHeadFirst)}>
                  <div className={styles.contentColumnHead}>Jadwal Dokter</div>
                </th>
                {time.map((item, index) => (
                  <th
                    key={index}
                    className={cx(
                      styles.columnHead,
                      item.start >= "09:00" && item.start <= "17:00"
                        ? styles.highlightTime
                        : ""
                    )}
                  >
                    {item.start} - {item.end}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataReservation.map((item, index) => (
                <tr key={index}>
                  <td className={cx(styles.columnBody, styles.columnBodyFirst)}>
                    <div className={styles.contentColumnBodyFirst}>
                      <div className={styles.columnBodyFirstContent}>
                        {item.doctorName}
                      </div>
                    </div>
                  </td>
                  {time.map((hour, indexTime) => {
                    const reservationsAtHour = item.reservations.filter(
                      (value) => value.time === hour.start
                    );
                    const noActiveSchedule = item.reservations.find(
                      (value) => value.time === hour.start
                    )?.active;
                    const noPatients =
                      reservationsAtHour.length > 0 &&
                      reservationsAtHour.every(
                        (reservation) => reservation.patients.length === 0
                      );
                    return (
                      <td
                        className={cx(
                          styles.columnBody,
                          noPatients && styles.columnVerticalCenter,
                          (!noActiveSchedule ||
                            reservationsAtHour.length === 0) &&
                            styles.columnBodyDisable
                        )}
                        key={indexTime}
                      >
                        <div className={styles.cellContent}>
                          {reservationsAtHour.map((reservation) => (
                            <div className={styles.wrapperColumnBody}>
                              {reservation.patients.map((patient) => (
                                <div
                                  onClick={() => setShowModalDetail(patient.id)}
                                  className={cx(styles.cardReservation, {
                                    [styles.cardReservationBooking]:
                                      patient.status === "BOOKED",
                                    [styles.cardReservationActive]:
                                      patient.status === "ACTIVE",
                                    [styles.cardReservationDone]:
                                      patient.status === "DONE",
                                    [styles.cardReservationCancel]:
                                      patient.status === "CANCELLED",
                                  })}
                                >
                                  <p className="mb-0 font-w500">
                                    {patient.name}
                                  </p>
                                  <p className="mb-0">
                                    {localize.getText("sequenceNumber")}:{" "}
                                    {patient.noSequence}
                                  </p>
                                  {/* <div
                                    className={styles.circleInfo}
                                    onClick={() =>
                                      setShowModalDetail(patient.id)
                                    }
                                  >
                                    <i className="fas fa-ellipsis-v"></i>
                                  </div> */}
                                </div>
                              ))}
                              <div
                                className={styles.buttonAddReservation}
                                onClick={() =>
                                  setShowModalAdd({
                                    doctorId: item.doctorId,
                                    doctorName: item.doctorName,
                                    durationVisit: item.durationVisit,
                                    availableSchedule:
                                      reservation.availableSchedule,
                                  })
                                }
                              >
                                <i className="fas fa-plus"></i>
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Spinner>
    </div>
  );
}
