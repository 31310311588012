import React from "react";
import { useLocalization } from "../../../../../context/LocalizeContextProvider";
import { Link } from "react-router-dom";
import Table from "../../../../components/Table";
import Button from "../../../../components/Button";
import { roleUser } from "../../../../../utils/constants";
import { useAuth } from "../../../../../context/AuthProvider";

export default function ListStafClinicComponent({
  dataStaf,
  isLoading = false,
  totalPage = 0,
  totalEntries = 0,
  sizePerPage = 0,
  handleSetSizePerPage,
  handlePagination,
  activePage,
}) {
  const { role } = useAuth();
  const localize = useLocalization();
  const isStaff = role === roleUser.ROLE_STAFF;

  const columns = [
    { label: localize.getText("name") },
    { label: localize.getText("role") },
    { label: localize.getText("dateOfBirth") },
    { label: localize.getText("gender") },
    { label: localize.getText("address"), style: { width: "200px" } },
    {
      label: localize.getText("action"),
      className: "text-center",
      style: { width: "200px" },
    },
  ];

  return (
    <div className="card">
      {!isStaff && (
        <div className="card-header d-flex justify-content-end">
          <Link to="/user-clinic/add-user-clinic">
            <Button label={localize.getText("addUser")} size="btn-sm" />
          </Link>
        </div>
      )}
      <div className="card-body">
        <Table
          columns={columns}
          loading={isLoading}
          activePage={activePage}
          totalPage={totalPage}
          totalShow={dataStaf.length}
          totalEntries={totalEntries}
          handlePagination={handlePagination}
          sizePerPage={sizePerPage}
          handleSizePerPage={handleSetSizePerPage}
        >
          {dataStaf.map((item, index) => (
            <tr key={index}>
              <td className="sorting_1">{item.name}</td>
              <td>{item.role}</td>
              <td>{item.userDob}</td>
              <td>{item.userGender}</td>
              <td>{item.userAddress}</td>
              <td>
                <div className="d-flex justify-content-center gap-3">
                  {item.role === roleUser.ROLE_DOCTOR && (
                    <Link
                      to={{
                        pathname: "/user-clinic/schedule-doctor-clinic",
                        search: `?id=${item.id}&doctor-name=${item.name}`,
                      }}
                    >
                      <Button variant="success">
                        <i class="fas fa-calendar-alt"></i>
                      </Button>
                    </Link>
                  )}
                  {!isStaff && (
                    <Button variant="danger">
                      <i class="fas fa-trash"></i>
                    </Button>
                  )}
                  <Link
                    to={{
                      pathname: "/user-clinic/detail-user-clinic",
                      search: `?id=${item.id}`,
                      state: item,
                    }}
                  >
                    <Button variant="warning">
                      <i class="fas fa-eye"></i>
                    </Button>
                  </Link>
                  {!isStaff && (
                    <Link
                      to={{
                        pathname: "/user-clinic/edit-user-clinic",
                        search: `?id=${item.id}`,
                        state: item,
                      }}
                    >
                      <Button variant="info">
                        <i class="fas fa-edit"></i>
                      </Button>
                    </Link>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
