import axios from "axios";
import { PER_PAGE_SIZE } from "../../utils/constants";

export const getItemOptions = async (businesId) => {
  try {
    let link =
      process.env.REACT_APP_LAMBDA_URL + `/v1/get-item-options/${businesId}`;

    const response = await axios.get(link);

    return response;
  } catch (error) {
    return error;
  }
};

export const postItem = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_LAMBDA_URL + `/v1/items`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const putItem = async (data) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_LAMBDA_URL + `/v1/items/${data.id}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getItems = async ({
  page = 1,
  size = PER_PAGE_SIZE,
  businessId,
}) => {
  try {
    let link = process.env.REACT_APP_LAMBDA_URL + `/v1/items/${businessId}?`;
    link = link.concat("page=" + page);
    link = link.concat("&size=" + size);

    const response = await axios.get(link);

    return response;
  } catch (error) {
    return error;
  }
};

export const getItemDetail = async (id) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_LAMBDA_URL + `/v1/items/detail/${id}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const deleteItem = async (id) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_LAMBDA_URL + `/v1/items/${id}`
    );

    return response;
  } catch (error) {
    return error;
  }
};
